import React, { useState } from 'react'
import CircularProgress from "@material-ui/core/CircularProgress";
import content from "../content";
import './css/index.scoped.css'

interface ProductBoxProps {
    product: any,
    addToCartLoading: boolean,
    wishlistLoading: boolean,
    onProductDeleteFromWishlist: (productId: number) => void
    onProductAddToWishlist: (productId: number) => void
    onProductAddToCart: (product: any) => void
    onProductIncreaseCartQuantity?: (product: any) => void
    onProductDecreaseCartQuantity?: (product: any) => void
    noIncreaseDecreaseButtons?: boolean
    history?:any
}

const Spinner: React.FC<{ white?: boolean }> = (props) => (
    <CircularProgress thickness={5} size={21} style={{ color: props.white ? 'white' : 'var(--color-cyan)' }} />
)

const ProductBox: React.FC<ProductBoxProps> = ({
    product, onProductDeleteFromWishlist, onProductAddToWishlist, onProductAddToCart,
    addToCartLoading, onProductIncreaseCartQuantity, onProductDecreaseCartQuantity, wishlistLoading,
    noIncreaseDecreaseButtons,history
}) => {
    const [addToCartButtonHover, setAddToCartButtonHover] = useState(false)
    
    let productDefaultWeight = <>{product.attributes.weight ?? ""} {product.attributes.weight_unit ?? ""}</>
    let productDefaultPrice = product.attributes.on_sale ? product.attributes.price_including_tax : product.attributes.actual_price_including_tax
    let productDefaultNonDiscountedPrice = product.attributes.on_sale ? product.attributes.actual_price_including_tax : null
    let productDefaultCartQuantity = product.attributes.cart_quantity
    let productDefaultStockQuantity = product.attributes.stock_qty
    let productDefaultImage = product.attributes.images?.data[0].attributes.url

    if (product.attributes.default_variant) {
        const defaultVariantDetails = product.attributes.catalogue_variants.find((v: any) => (
            Number(v.id) === product.attributes.default_variant.id
        ))
        if (defaultVariantDetails) {
            productDefaultPrice = defaultVariantDetails.attributes.on_sale ?
            (
                defaultVariantDetails.attributes.price_including_tax ?? defaultVariantDetails.attributes.sale_price
            ) : (
                defaultVariantDetails.attributes.actual_price_including_tax ?? defaultVariantDetails.attributes.price
            )
            productDefaultNonDiscountedPrice = defaultVariantDetails.attributes.on_sale ? (
                defaultVariantDetails.attributes.actual_price_including_tax ?? defaultVariantDetails.attributes.price
            ) : null
            const weightDetails = defaultVariantDetails.attributes.catalogue_variant_properties?defaultVariantDetails.attributes?.catalogue_variant_properties:defaultVariantDetails.attributes?.variant_property.data.find((p: any) => (
                p.attributes.variant_name.trim().toLowerCase() == "weight"||p.attributes.variant_name.trim().toLowerCase() =="size"||p.attributes.variant_name.trim().toLowerCase() =="colour"||p.attributes.variant_name.trim().toLowerCase() =="pack size"||p.attributes.variant_name.trim().toLowerCase() =="pcs"||p.attributes.variant_name.trim().toLowerCase() =="dummy"
              ))
              if (weightDetails.length==undefined) {
                  productDefaultWeight = <>{weightDetails.attributes.property_name}</>
              }else{
                  productDefaultWeight = <>{weightDetails[0].attributes.property_name}</>
              }
            productDefaultCartQuantity = defaultVariantDetails.attributes.cart_quantity
            productDefaultStockQuantity = defaultVariantDetails.attributes.stock_qty
            productDefaultImage = defaultVariantDetails.attributes.images?.data[0].attributes.url
        }
    }

    return (
        <div className={`product_card_single ${productDefaultCartQuantity && !noIncreaseDecreaseButtons ? 'added_to_cart' : ''}`}>
            <a href={`/shop/${product.id}`} className="product_link d-flex flex-fill flex-column">
                <div className="d-flex flex-fill flex-column">
                    <div className='card_image'>
                        <img className='card_img' src={productDefaultImage
                            || "https://www.publicdomainpictures.net/pictures/280000/velka/not-found-image-15383864787lu.jpg"
                        } alt="Card image cap" />

                        <button className='product_wishlist_button' onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            product.attributes.wishlisted
                                ? onProductDeleteFromWishlist(product.id)
                                : onProductAddToWishlist(product.id)
                        }}>
                            {
                                wishlistLoading ? <Spinner /> : <svg className={`likebuttononimage${product.attributes.wishlisted ? ' active' : ''}`} width="21" height="21" viewBox="0 0 24 24" fill="#fff" stroke="#8899a4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
                            }
                        </button>

                    </div>
                    <div className='d-flex flex-column flex-fill'>
                        <div className='product_name'>{product.attributes.name}</div>
                        <div className='product_price'>
                            <div>
                                <span>{JSON.parse(localStorage.getItem('countryCode') ?? "{}")?.countryCode} {Number(productDefaultPrice?.toString()).toFixed(2)}</span>
                                {
                                    productDefaultNonDiscountedPrice && <span className='product_non_discounted_price'>{JSON.parse(localStorage.getItem('countryCode') ?? "{}")?.countryCode} {Number(productDefaultNonDiscountedPrice.toString()).toFixed(2)}</span>
                                }
                            </div>
                        </div>
                        <div className='product_quantity'>{productDefaultWeight}</div>
                    </div>
                </div>
            </a>
            {
                productDefaultCartQuantity ? (
                    noIncreaseDecreaseButtons ? (
                        <button className='product_add_cart' onClick={() => {window.location.replace('/cart')}}
                            onMouseEnter={() => setAddToCartButtonHover(true)}
                            onMouseLeave={() => setAddToCartButtonHover(false)}>
                            {addToCartLoading ? <Spinner white={addToCartButtonHover} /> : content.goToCart}
                        </button>
                    ) : (
                        <div className='product_add_cart_count'>
                            <button className='cart_decrease' disabled={addToCartLoading} onClick={() => {
                                onProductDecreaseCartQuantity?.(product)
                            }}>
                                -
                            </button>
                            <div className='cart_count flex-fill'>{
                                addToCartLoading ? <Spinner /> : productDefaultCartQuantity
                            }</div>
                            <button className='cart_increase' disabled={addToCartLoading || productDefaultCartQuantity >= productDefaultStockQuantity} onClick={() => {
                                if (productDefaultCartQuantity < productDefaultStockQuantity) {
                                    onProductIncreaseCartQuantity?.(product)
                                }
                            }}>
                                +
                            </button>
                        </div>
                    )

                ) : (
                    <button className='product_add_cart' onClick={() =>{
                        if(product.attributes.catalogue_variants!=null &&history!=undefined){
                            history.push(
                                "/shop/" + product.id
                                );
                            }else{
                                onProductAddToCart(product)
                            }
                        }
                    }
                        onMouseEnter={() => setAddToCartButtonHover(true)}
                        onMouseLeave={() => setAddToCartButtonHover(false)}>
                        {addToCartLoading ? <Spinner white={addToCartButtonHover} /> : content.addToCart}
                    </button>
                )
            }
        </div>
    )
}

export default ProductBox